import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ButtonSp from '../../components/ButtonSp';

import InputTextSp from '../../components/InputTextSp';
import LabelSp from '../../components/LabelSp';
import { showMessage } from '../../components/MessageDialog';
import history from '../../services/history';
import DesenvolvimentoService from '../../services/DesenvolvimentoService';
import { DesenvolvimentoModel } from '../../util/Models';

import {
  carregaListaVersoes,
  cloneObj,
  errorHandle,
  isDate,
  strToDate,
  validateFields,
} from '../../util/functions';

import { StateScreen } from '../constants';
import DropdownSp from '../../components/DropdownSp';

import InputTextareaSp from '../../components/InputTextareaSp';
import SelectButtons from '../../components/SelectButtons';

import DropDownLazy from '../../components/DropdownLazy';
import CalendarSp from '../../components/CalendarSp';
import AuthService from '../../services/AuthService';
import SistemaService from '../../services/SistemaService';
import ClienteService from '../../services/ClienteService';
import { store } from '../../store';
import { showLookup } from '../../store/modules/dialog/actions';

import ColaboradorService from '../../services/ColaboradorService';
import { cfgPtBr } from '../../config/Constantes';

import CrudAnexo from './crudAnexo';
import CrudTeste from './crudTeste';

function DesenvolvimentoCrud(props) {
  DesenvolvimentoCrud.propTypes = {
    stateScreen: PropTypes.string.isRequired,
    idSelected: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    closeOnSaveCancel: PropTypes.bool,
  };

  DesenvolvimentoCrud.defaultProps = {
    messageConfirmation: 'Confirma os dados',
    showConfirmation: false,
    closeOnSaveCancel: false,
  };

  const { stateScreen, idSelected, onClose, toBack, closeOnSaveCancel } = props;

  const colaboradorResponsavelUsuario = AuthService.getUsuario().colaborador
    ? {
        label: AuthService.getUsuario().colaborador.nome,
        value: AuthService.getUsuario().colaborador.id,
      }
    : null;

  let eBack = '';
  let uBack = '';
  if (toBack) {
    eBack = `&toBack=${toBack}`;
    uBack = `?toBack=${toBack}`;
  }

  // states
  const [desenvolvimento, setDesenvolvimento] = useState(new DesenvolvimentoModel());
  const [sistemaSelecionada, setSistemaSelecionada] = useState();
  const [errorLoadRecord, setErrorLoadRecord] = useState(false);
  const [paginaSelecionada, setPaginaSelecionada] = useState(0);
  const [colaboradorResponsavelSelecionado, setColaboradorResponsavelSelecionado] = useState(
    colaboradorResponsavelUsuario
  );
  const [colaboradorProgramadorSelecionado, setColaboradorProgramadorSelecionado] = useState();

  // useCallbacks
  const loadRecord = useCallback(async _id => {
    try {
      const retorno = await DesenvolvimentoService.findById(_id);

      const sistema = retorno.sistema
        ? {
            label: retorno.sistema.nome,
            value: retorno.sistema.id,
          }
        : null;

      const colaboradorProgramador = retorno.colaboradorProgramador
        ? {
            label: retorno.colaboradorProgramador.nome,
            value: retorno.colaboradorProgramador.id,
          }
        : null;

      const colaboradorResponsavel = retorno.colaboradorResponsavel
        ? {
            label: retorno.colaboradorResponsavel.nome,
            value: retorno.colaboradorResponsavel.id,
          }
        : null;

      if (isDate(retorno.dataLancamento)) {
        retorno.dataLancamento = strToDate(retorno.dataLancamento);
      }

      if (isDate(retorno.dataConclusao)) {
        retorno.dataConclusao = strToDate(retorno.dataConclusao);
      }

      if (isDate(retorno.dataPrevisao)) {
        retorno.dataPrevisao = strToDate(retorno.dataPrevisao);
      }

      setSistemaSelecionada(sistema);
      setDesenvolvimento(retorno);
      setColaboradorResponsavelSelecionado(colaboradorResponsavel);
      setColaboradorProgramadorSelecionado(colaboradorProgramador);

      setErrorLoadRecord(false);
    } catch (err) {
      setErrorLoadRecord(true);
      errorHandle(err);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadColaborador = useCallback(async (_nome, tipo) => {
    if (_nome !== undefined) {
      const r = await ColaboradorService.findAll({
        nome: _nome,
        limit: 50,
        tipo,
      });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  const lookupCliente = () => {
    const { dispatch } = store;
    const lookupConfig = ClienteService.getLookupConfig(objReturn => {
      const { id, nome } = objReturn;
      const cliente = {
        id,
        nome,
      };
      setDesenvolvimento({ ...desenvolvimento, cliente, idCliente: cliente.id });
    });

    dispatch(showLookup(lookupConfig));
  };

  const loadSistema = useCallback(async _nome => {
    if (_nome !== undefined) {
      const r = await SistemaService.findAll({ nome: _nome, limit: 50 });

      const retorno = r.items.map(e => {
        return {
          label: e.nome,
          value: e.id,
        };
      });
      return retorno;
    }
    return [];
  }, []);

  // funcoes
  function viewMode() {
    return stateScreen === StateScreen.stView || errorLoadRecord;
  }

  function bloqueiaResponsavel() {
    return !AuthService.isPerfilGerente();
  }

  function handleBack() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      showMessage('Confirmação', 'Abandonar mudanças?', idx => {
        if (idx === 1) {
          if (stateScreen === StateScreen.stInsert || closeOnSaveCancel) {
            onClose();
          } else {
            history.push(`/desenvolvimentos/${idSelected}?view${eBack}`);
          }
        }
      });
    }
  }

  function handleSave() {
    if (stateScreen === StateScreen.stView) {
      onClose();
    } else {
      salvarRecord();
    }
  }

  async function salvarRecord() {
    try {
      let retorno;
      const DesenvolvimentoSalvar = cloneObj(desenvolvimento);
      if (stateScreen === StateScreen.stInsert) {
        retorno = await DesenvolvimentoService.insert(DesenvolvimentoSalvar);
      } else {
        retorno = await DesenvolvimentoService.update(DesenvolvimentoSalvar);
      }
      toast.success('Registro salvo com sucesso.');
      if (closeOnSaveCancel) {
        onClose(retorno);
      } else {
        history.push(`/desenvolvimentos/${retorno.id}?view${eBack}`);
      }
    } catch (err) {
      errorHandle(err);
    }
  }

  // useEffects
  useEffect(() => {
    if (stateScreen === StateScreen.stUpdate || stateScreen === StateScreen.stView) {
      loadRecord(idSelected);
    } else if (stateScreen === StateScreen.stInsert) {
      const novo = new DesenvolvimentoModel();
      novo.idUsuario = AuthService.getUsuario().id;
      novo.usuario = AuthService.getUsuario();
      novo.idColaboradorResponsavel = AuthService.getUsuario().colaborador?.id;
      novo.colaboradorResponsavel = AuthService.getUsuario().colaborador;

      setDesenvolvimento(novo);
    }
  }, [loadRecord, idSelected, stateScreen, colaboradorResponsavelUsuario.id]);

  const renderPaginaSelecionada = () => {
    if (paginaSelecionada === 0) {
      return renderCadastro();
    }
    if (paginaSelecionada === 1) {
      return renderArquivos();
    }

    return renderTeste();
  };

  const renderArquivos = () => {
    return <CrudAnexo idSelected={idSelected} viewMode={viewMode} handleBack={handleBack} />;
  };

  const renderTeste = () => {
    return (
      <CrudTeste
        idSelected={idSelected}
        viewMode={viewMode}
        handleBack={handleBack}
        colaboradorResponsavelUsuario={colaboradorResponsavelUsuario}
        loadColaborador={loadColaborador}
        permiteLancarTestes={desenvolvimento.status === 4 || desenvolvimento.status === 6}
      />
    );
  };

  const renderCadastro = () => {
    return (
      <>
        <div
          className="p-grid p-col-12 p-md-7 p-lg-6 p-fluid"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="p-col-6 p-md-4 p-fluid">
            <LabelSp>Data</LabelSp>
            <CalendarSp
              showOnFocus={false}
              showIcon
              required
              dateFormat="dd/mm/yy"
              disabled={viewMode()}
              locale={cfgPtBr}
              value={desenvolvimento.dataLancamento}
              appendTo={document.body}
              onChange={e => {
                setDesenvolvimento({
                  ...desenvolvimento,
                  dataLancamento: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-6 p-md-4 p-fluid">
            <LabelSp>Previsão</LabelSp>
            <CalendarSp
              showOnFocus={false}
              showIcon
              required
              locale={cfgPtBr}
              dateFormat="dd/mm/yy"
              disabled={
                viewMode() || desenvolvimento.status === 0 || desenvolvimento.status === 1
              }
              value={desenvolvimento.dataPrevisao}
              appendTo={document.body}
              onChange={e => {
                setDesenvolvimento({
                  ...desenvolvimento,
                  dataPrevisao: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-12 p-md-4 p-fluid">
            <LabelSp>Status</LabelSp>
            <DropdownSp
              options={[
                { value: 0, label: 'Pendente' },
                { value: 1, label: 'Pré-aprovado' },
                { value: 2, label: 'Aprovado' },
                { value: 3, label: 'Desenvolvendo' },
                { value: 4, label: 'Concluído' },
                { value: 5, label: 'Cancelado' },
                { value: 6, label: 'Liberado' },
              ]}
              disabled={viewMode() || bloqueiaResponsavel()}
              placeholder="Selecione"
              required
              value={desenvolvimento.status}
              onChange={e => {
                let {
                  dataPrevisao,
                  dataConclusao,
                  versaoLiberacao,
                  colaboradorProgramador,
                  idColaboradorProgramador,
                  historicoVersao,
                } = desenvolvimento;

                if (e.target.value === 0) {
                  dataPrevisao = null;
                  dataConclusao = null;
                  versaoLiberacao = '';
                  colaboradorProgramador = null;
                  idColaboradorProgramador = null;
                  historicoVersao = '';
                  setColaboradorProgramadorSelecionado(null);
                }

                if (e.target.value === 1) {
                  dataConclusao = null;
                  versaoLiberacao = '';
                  colaboradorProgramador = null;
                  idColaboradorProgramador = null;
                  historicoVersao = '';
                  setColaboradorProgramadorSelecionado(null);
                }

                if (e.target.value === 2) {
                  dataConclusao = null;
                  versaoLiberacao = '';
                  historicoVersao = '';
                }

                if (e.target.value === 4) {
                  dataConclusao = null;
                  versaoLiberacao = '';
                }

                setDesenvolvimento({
                  ...desenvolvimento,
                  dataPrevisao,
                  dataConclusao,
                  versaoLiberacao,
                  colaboradorProgramador,
                  idColaboradorProgramador,
                  historicoVersao,
                  status: e.target.value,
                });
              }}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-5 p-lg-4 p-fluid">
          <LabelSp>Cliente</LabelSp>
          <div className="p-inputgroup">
            <ButtonSp
              type="button"
              icon="pi pi-search"
              className="p-button-secondary buttons"
              title="Buscar Cliente"
              onClick={() => lookupCliente()}
            />

            <InputTextSp
              className="p-error"
              value={desenvolvimento.cliente?.nome || 'Selecione'}
              disabled
            />
          </div>
        </div>

        <div className="p-col-12 p-md-4 p-lg-2 p-fluid">
          <LabelSp>Sistema</LabelSp>
          <DropDownLazy
            autoLoad
            placeholder="Selecione"
            required
            value={sistemaSelecionada}
            onChange={e => {
              const sistema = e ? { id: e.value, nome: e.label } : null;
              setDesenvolvimento({
                ...desenvolvimento,
                sistema,
                idSistema: e?.value,
              });
            }}
            onFilter={async txtFilter => {
              const retorno = await loadSistema(txtFilter);
              return retorno;
            }}
            disabled={viewMode()}
          />
        </div>

        <div className="p-col-6 p-md-4 p-lg-2 p-fluid">
          <LabelSp>Responsável</LabelSp>
          <DropDownLazy
            autoLoad
            required
            disabled={viewMode() || bloqueiaResponsavel()}
            placeholder="Selecione"
            onChange={e => {
              const colab = e ? { id: e.value, nome: e.label } : null;
              setColaboradorResponsavelSelecionado(e);
              setDesenvolvimento({
                ...desenvolvimento,
                colaboradorResponsavel: colab,
                idColaboradorResponsavel: e?.value,
              });
            }}
            value={colaboradorResponsavelSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter);
              return retorno;
            }}
          />
        </div>

        <div className="p-col-6 p-md-4 p-lg-2 p-fluid">
          <LabelSp>Programador</LabelSp>
          <DropDownLazy
            autoLoad
            required={desenvolvimento.status !== 2}
            showClear
            disabled={
              viewMode() ||
              (desenvolvimento.status !== 1 &&
                desenvolvimento.status !== 2 &&
                desenvolvimento.status !== 3 &&
                desenvolvimento.status !== 4 &&
                desenvolvimento.status !== 6)
            }
            placeholder="Selecione"
            onChange={e => {
              const colaboradorProgramador = e ? { id: e.value, nome: e.label } : null;
              setColaboradorProgramadorSelecionado(e);
              setDesenvolvimento({
                ...desenvolvimento,
                colaboradorProgramador,
                idColaboradorProgramador: e?.value || null,
              });
            }}
            value={colaboradorProgramadorSelecionado}
            onFilter={async txtFilter => {
              const retorno = await loadColaborador(txtFilter, '0');
              return retorno;
            }}
          />
        </div>

        <div
          className="p-grid p-col-12 p-md-6 p-lg-4 p-fluid"
          style={{ margin: 0, padding: 0 }}
        >
          <div className="p-col-4 p-fluid">
            <LabelSp>Conclusão</LabelSp>
            <CalendarSp
              showOnFocus={false}
              showIcon
              required
              dateFormat="dd/mm/yy"
              disabled={
                viewMode() || (desenvolvimento.status !== 4 && desenvolvimento.status !== 6)
              }
              value={desenvolvimento.dataConclusao}
              appendTo={document.body}
              onChange={e => {
                setDesenvolvimento({
                  ...desenvolvimento,
                  dataConclusao: e.target.value,
                });
              }}
            />
          </div>

          <div className="p-col-4 p-fluid">
            <LabelSp>Versão</LabelSp>
            <DropdownSp
              filter
              options={carregaListaVersoes(false)}
              placeholder="selecione"
              required
              disabled={
                viewMode() || (desenvolvimento.status !== 4 && desenvolvimento.status !== 6)
              }
              value={desenvolvimento.versaoLiberacao}
              onChange={e => {
                setDesenvolvimento({ ...desenvolvimento, versaoLiberacao: e.target?.value });
              }}
            />
          </div>

          <div className="p-col-4  p-fluid">
            <LabelSp>Prioridade</LabelSp>
            <DropdownSp
              options={[
                { label: 'Baixa', value: 0 },
                { label: 'Moderada', value: 1 },
                { label: 'Normal', value: 2 },
                { label: 'Alta', value: 3 },
                { label: 'Máxima', value: 4 },
                { label: 'Bug', value: 5 },
              ]}
              placeholder="selecione"
              required
              value={desenvolvimento.prioridade}
              onChange={e => {
                setDesenvolvimento({ ...desenvolvimento, prioridade: e.target?.value });
              }}
            />
          </div>
        </div>

        <div className="p-col-12 p-md-6 p-lg-4 p-fluid">
          <LabelSp>Descrição Resumida</LabelSp>
          <InputTextSp
            value={desenvolvimento.descricaoResumida}
            disabled={viewMode()}
            maxLength={80}
            required
            onChange={e => {
              setDesenvolvimento({ ...desenvolvimento, descricaoResumida: e.target.value });
            }}
          />
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 p-fluid">
          <LabelSp>Descrição</LabelSp>
          <InputTextareaSp
            required
            autoResize={false}
            value={desenvolvimento.descricaoSolicitacao}
            maxLength={2000}
            rows={7}
            resize={false}
            onChange={e => {
              setDesenvolvimento({
                ...desenvolvimento,
                descricaoSolicitacao: e.target.value,
              });
            }}
            disabled={viewMode()}
          />
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 p-fluid">
          <LabelSp>Descrição Desenvolvimento</LabelSp>
          <InputTextareaSp
            autoResize={false}
            value={desenvolvimento.descricaoDesenvolvimento}
            maxLength={2000}
            rows={7}
            resize={false}
            onChange={e => {
              setDesenvolvimento({
                ...desenvolvimento,
                descricaoDesenvolvimento: e.target.value,
              });
            }}
            disabled={
              viewMode() ||
              (desenvolvimento.status !== 4 &&
                desenvolvimento.status !== 6 &&
                desenvolvimento.status !== 3 &&
                desenvolvimento.status !== 2)
            }
          />
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 p-fluid">
          <LabelSp>Histórico Versão/Cancelamento</LabelSp>
          <InputTextareaSp
            required
            autoResize={false}
            value={desenvolvimento.historicoVersao}
            maxLength={2000}
            rows={9}
            resize={false}
            onChange={e => {
              setDesenvolvimento({
                ...desenvolvimento,
                historicoVersao: e.target.value,
              });
            }}
            disabled={viewMode() || desenvolvimento.status < 4}
          />
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 p-fluid">
          <LabelSp>Roteiro de Testes</LabelSp>
          <InputTextareaSp
            autoResize={false}
            value={desenvolvimento.roteiroTestes}
            maxLength={2000}
            rows={9}
            resize={false}
            onChange={e => {
              setDesenvolvimento({
                ...desenvolvimento,
                roteiroTestes: e.target.value,
              });
            }}
            disabled={
              viewMode() || (desenvolvimento.status !== 4 && desenvolvimento.status !== 6)
            }
          />
        </div>

        <div className="p-col-12 p-md-12 p-lg-12 p-fluid">
          <LabelSp>Observação</LabelSp>
          <InputTextareaSp
            autoResize={false}
            value={desenvolvimento.observacao}
            maxLength={2000}
            rows={5}
            resize={false}
            onChange={e => {
              setDesenvolvimento({
                ...desenvolvimento,
                observacao: e.target.value,
              });
            }}
            disabled={viewMode() || desenvolvimento.status === 0}
          />
        </div>

        <div className="p-col-12 p-lg-12" style={{ textAlign: 'start' }}>
          {!viewMode() ? (
            <ButtonSp
              className="p-button-success"
              icon="pi pi-save"
              label="Salvar"
              keyAccess="S"
              disabled={
                !validateFields(desenvolvimento, [
                  'dataLancamento',
                  'idCliente',
                  'idSistema',
                  'idColaboradorResponsavel',
                  desenvolvimento.status === 4 ||
                  desenvolvimento.status === 3 ||
                  desenvolvimento.status === 6
                    ? 'idColaboradorProgramador'
                    : '',
                  'descricaoSolicitacao',
                  'descricaoResumida',
                  desenvolvimento.status === 4 || desenvolvimento.status === 6
                    ? 'historicoVersao'
                    : '',
                  desenvolvimento.status === 4 || desenvolvimento.status === 6
                    ? 'dataConclusao'
                    : '',
                  desenvolvimento.status === 4 || desenvolvimento.status === 6
                    ? 'versaoLiberacao'
                    : '',
                  desenvolvimento.status > 1 ? 'dataPrevisao' : '',
                ])
              }
              showConfirmation
              onClick={handleSave}
            />
          ) : null}

          {viewMode() ? (
            <ButtonSp
              className="p-button-primary"
              icon="pi pi-pencil"
              label="Alterar"
              disabled={!AuthService.checkRoles('DESENVOLVIMENTO_ALTERAR')}
              onClick={() => {
                history.push(`/desenvolvimentos/${idSelected}${uBack}`);
              }}
            />
          ) : null}

          <ButtonSp
            className={viewMode() ? 'p-button-secondary' : 'p-button-danger'}
            label={viewMode() ? 'Voltar' : 'Cancelar'}
            icon="pi pi-chevron-circle-left"
            onClick={handleBack}
          />
        </div>
      </>
    );
  };

  // render principal
  const buttons = ['Cadastro'];
  if (viewMode()) {
    buttons.push('Arquivos');
    buttons.push('Testes');
  }

  return (
    <>
      <div className="p-col-12 p-lg-12" style={{ padding: 0, margin: 0 }}>
        <SelectButtons
          widthButton={200}
          buttons={buttons}
          selectedIndex={paginaSelecionada}
          onClick={itemIndex => setPaginaSelecionada(itemIndex)}
        />
      </div>
      {renderPaginaSelecionada()}
    </>
  );
}

export default DesenvolvimentoCrud;
